<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div
      class="page-header-image"
      style="
        background-image: url('https://seed-cup.oss-cn-hangzhou.aliyuncs.com/seedcup/img/opening_ceremony.jpg');
        filter: blur(8px);
      "
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card type="login" plain>
            <div slot="header" class="header-font">SeedCup</div>
            <tabs>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i>密码登录
                </template>
                <template>
                  <el-form :model="loginForm" ref="loginForm">
                    <div class="row">
                      <div class="col">
                        <el-form-item prop="username">
                          <fg-input
                            v-model="loginForm.username"
                            class="no-border input-lg"
                            addon-left-icon="now-ui-icons users_circle-08"
                            placeholder="username"
                          >
                          </fg-input>
                        </el-form-item>
                      </div>
                      <div class="col">
                        <el-form-item prop="password">
                          <fg-input
                            type="password"
                            v-model="loginForm.password"
                            class="no-border input-lg"
                            addon-left-icon="now-ui-icons text_caps-small"
                            placeholder="password"
                          >
                          </fg-input>
                        </el-form-item>
                      </div>
                    </div>
                  </el-form>
                  <div class="card-footer text-center" @click="login_pwd()">
                    <a
                      href="#login"
                      class="btn btn-primary btn-round btn-lg btn-block"
                      >Get Started</a
                    >
                  </div>
                </template>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i>验证码登录
                </template>
                <el-form :model="loginForm" ref="loginForm">
                  <div class="row">
                    <el-col>
                      <el-form-item prop="phone">
                        <fg-input
                          v-model="loginForm.phone"
                          class="no-border input-lg"
                          addon-left-icon="now-ui-icons users_circle-08"
                          placeholder="phone"
                        >
                        </fg-input>
                      </el-form-item>
                    </el-col>
                    <el-form-item props="code">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="14">
                          <div
                            class="compensate-padding"
                            style="padding: 10px 0"
                          >
                            <fg-input
                              class="no-border"
                              placeholder="验证码"
                              addon-left-icon="now-ui-icons ui-1_send"
                              v-model="loginForm.code"
                            >
                            </fg-input>
                          </div>
                        </el-col>
                        <el-col :span="9">
                          <n-button
                            class="btn btn-simple btn-round btn-white btn-mi"
                            :disabled="btnBool"
                            @click="send_sms"
                          >
                            {{ this.sms_interval }}
                          </n-button>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </div>
                </el-form>
                <div class="card-footer text-center" @click="login_sms()">
                  <a
                    href="#login"
                    class="btn btn-primary btn-round btn-lg btn-block"
                    >Get Started</a
                  >
                </div>
              </tab-pane>
            </tabs>
            <template slot="raw-content">
              <div class="pull-left" @click="toSignUp()">
                <h6>
                  <a href="#pablo" class="link footer-link">Create Account</a>
                </h6>
              </div>
              <div class="pull-right">
                <h6>
                  <a href="#pablo" class="link footer-link">Need Help?</a>
                </h6>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, FormGroupInput, TabPane, Tabs } from '@/components'
import request from '@/apis/request'
import { setToken } from '@/utils/storage'
export default {
  name: 'login-page',
  bodyClass: 'login-page',
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        code: '',
        phone: '',
      },
      btnBool: false,
      is_send: false,
      sms_interval: '获取验证码',
    }
  },
  methods: {
    login_pwd() {
      console.log(this.loginForm)
      request
        .post('/user/login/pwd', this.loginForm)
        .then((res) => {
          if (res.code === 200) {
            setToken(res.data.token)
            this.$alert('登录成功')
            this.$router.push('/')
          } else if (res.code === 400) {
            this.$alert('用户名或密码错误')
          }
        })
        .catch((err) => {
          if (err.response.data.code == 400) {
            this.$alert('用户名或密码错误')
          }
        })
    },
    login_sms() {
      console.log(this.loginForm)
      request
        .post('/user/login/sms', this.loginForm)
        .then((res) => {
          if (res.code === 200) {
            setToken(res.data.token)
            this.$alert('登录成功')
            this.$router.push('/')
          } else if (res.code === 400) {
            this.$alert('用户名或密码错误')
          }
        })
        .catch((err) => {
          if (err.response.data.code == 400) {
            this.$alert('手机号或验证码错误')
          }
        })
    },
    toSignUp() {
      this.$router.push('/signup')
    },
    send_sms: function () {
      if (!this.loginForm.phone.match(/^1[3-9][0-9]{9}$/)) {
        this.$message({
          message: '手机号有误',
          type: 'warning',
          duration: 1000,
          onClose: () => {
            this.loginForm.phone = ''
          },
        })
        return false
      }
      this.is_send = true
      //校验手机号如果存在就不用注册，直接去登录
      // this.$http
      //   .get(this.$BASE_URL + "user/check_phone/", {
      //     params: { phone: this.mobile },
      //   })
      //   .then((res) => {
      //     console.log(res.data);
      //     if (res.data.code == 100) {
      //       this.is_send = false;
      //       this.$message({
      //         message: "该账号已经存在，请直接去登录",
      //         type: "success",
      //       });
      //       this.is_send = true;
      //     } else {
      //       this.is_send = true;
      //       this.$message({
      //         message: "可以正常注册",
      //         type: "warning",
      //       });
      //     }
      //   });
      console.log('go to sending')
      this.sending()
    },
    sending: function () {
      console.log('sending')
      if (!this.is_send) return
      this.is_send = false
      let sms_interval_time = 60
      this.sms_interval = '发送中'
      let timer = setInterval(() => {
        if (sms_interval_time <= 1) {
          clearInterval(timer)
          this.sms_interval = '获取验证码'
          this.is_send = true // 重新回复点击发送功能的条件
        } else {
          sms_interval_time -= 1
          this.sms_interval = `${sms_interval_time}秒后再发`
        }
      }, 1000)
      request.post('user/sms', { phone: this.loginForm.phone }).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            message: '验证码已发送',
            type: 'success',
          })
        } else {
          this.$message({
            message: '发送失败，请稍后再试。',
            type: 'error',
          })
        }
      })
    },
  },
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    TabPane,
    Tabs,
  },
}
</script>
<style scoped>
.header-font {
  font-size: 40px;
  padding: 20px 20px;
}

.input-lg {
  margin-bottom: 0px !important;
}
</style>
