<template>
  <div class="introduction">
    <vue-particles
      class="login-bg"
      color="#39AFFD"
      :particleOpacity="0.7"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="4"
      linesColor="#8DD1FE"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <div class="introduction-title">
      <h1>大赛简介</h1>
    </div>
    <div class="row">
      <div class="col-sm-8 col-md-7">
        <div class="introduction-content">
          <div>
            <p>
              自2005年创办以来，“种子杯”已成功举办了十七届。从院系内开展，到全校广泛参与，“种子杯”的影响力不断加大，是历年校科技节重点项目。如今已为武汉市各大高校之间交流切磋的一项重大赛事，吸引着众多热爱编程、希望大显身手的同学参赛。
              作为一项创新型软件编程大赛，“种子杯”致力于为爱好编程的同学们提供一个共同交流、切磋技艺的平台，培养学生积极思考、敢于创新、敢于尝试的品质，提升本科生同学对编程的热爱，和对前沿编程技术的接触和了解。让学生能够在自身独立思考与团队协作配合中，不断磨炼自己。“种子杯”秉持创新实践观念，赛事题目贴合实际、紧跟技术发展，部分题目背景和数据来源于赞助方实际运营数据，给予同学们和企业接轨的机会，了解到互联网企业的市场现状，让学生在更加真实的情境下，增加对前沿技术的了解，让同学们在成为一名优秀程序工程师的同时心系社会发展。
            </p>
            <p>
              {{year}}年度的华中科技大学“点石·种子杯”创新性软件编程PK赛很快将拉开帷幕，欢迎各方高手前来交流PK，展现自我。获奖队伍将会获得荣誉证书、奖金以及赛事纪念品！
              {{year}}年度的华中科技大学“点石·种子杯”创新性软件编程PK赛分为初赛、复赛、决赛三个阶段。想要参赛的同学只要报名组队即可参与初赛，初赛结束后，排名前16的队伍将进入复赛。最终组委会将根据复赛选手们的排名，选出前10支队伍参与决赛答辩。大赛官网将提供测评功能，选手可将建立的模型/结果提交至大赛官网，查看当前成绩并且参与排名。
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'introduction',
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>

<style>
.introduction {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.introduction-title {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: left;
  justify-content: left;
  padding-left: 5vw;
  padding-top: 8vh;
  position: relative;
}

.introduction-content {
  width: 100%;
  height: 100%;
  display: flex;
  position: inherit;
  align-items: left;
  justify-content: left;
  padding-left: 8vw;
  padding-top: 7vh;
  padding-bottom: 7vh;
  position: relative;
}

.introduction-picture {
  width: 100%;
  height: 100%;
  padding: 25% 25%;
  position: relative;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
}
</style>
