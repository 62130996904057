/**
 * @author Guo YiXing
 *
 * @brief
 * 全局Axios拦截器配置
 */

import axios from 'axios'
import { getToken } from '@/utils/storage'

const Axios = axios.create({
  baseURL: 'https://seedcup.dian.org.cn/api/',
  timeout: 20000,
})

/**
 * 请求拦截
 */

Axios.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = getToken()
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
/**
 * 响应拦截
 */
Axios.interceptors.response.use(
  (res) => {
    // switch (res.data.code) {
    //   case -1:
    //     Message.error("身份过期，请重新登录");
    //     break;
    //   default:
    // }
    return res.data
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default Axios
