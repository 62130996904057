<template>
  <div class="section section-tabs">
    <div class="container">
      <div class="title">
        <h4>REGULATIONS & BONUS</h4>
      </div>
      <div class="row">
        <div class="col-md-10 ml-auto col-xl-6 mr-auto">
          <p class="category">比赛说明</p>
          <!-- Nav tabs -->
          <card>
            <tabs
              slot="raw-content"
              tab-content-classes="tab-content-padding text-center"
            >
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons objects_umbrella-13"></i> 主要介绍
                </template>
                <p>
                  本次活动面向对象为武汉地区各大高校的在校本科生。为了鼓励低年级同学参加本次大赛，对<em>{{ new Date().getFullYear().toString().slice(2,4) }}</em>级同学组成的参赛队伍的参赛成绩予以适当加分。对于混合年级组成的队伍，以队伍中<em>最高年级</em>为队伍年级。
                </p>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i> 初赛
                </template>
                <p>
                  选手们线下自由组队（每队不超过3人）、自由线下发挥。
                  大赛官网将提供测评功能，选手可将结果提交至大赛官网，查看当前成绩并且参与排名。
                  最终，组委会将根据选手们的排名，选出前<em>16</em>支队伍参与复赛
                </p>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i> 复赛
                </template>
                <p>
                  选手需保持初赛队伍配置，<b>不得更换队友</b>，同初赛为自由线下发挥。大赛官网提供同初赛测评功能。最终组委会将根据选手们的排名，选出前<b>10</b>支队伍参与决赛答辩。
                </p>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i> 决赛
                </template>
                <p>
                  复赛得分最高的十支队伍将进入决赛，决赛比赛当天晚上19:00优胜队伍进行决赛答辩。进入决赛的来自华中科技大学的队伍成员将获得进入Dian团队的<b>直通车</b>资格。
                </p>
              </tab-pane>
            </tabs>
          </card>
        </div>
        <div class="col-md-10 ml-auto col-xl-6 mr-auto">
          <p class="category">比赛奖励</p>
          <!-- Tabs with Background on Card -->
          <div class="card">
            <tabs
              centered
              type="neutral"
              tab-nav-wrapper-classes="card-header"
              tab-content-classes="card-body text-center"
              data-background-color="orange"
            >
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i> 一等奖
                </template>
                <p>一等奖 X 1 位 10,000RMB</p>
                <p>团体第一名荣誉证书 参赛队员的荣誉证书</p>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i> 二等奖
                </template>
                <p>二等奖 X 2 位 5,000RMB</p>
                <p>团体第二名荣誉证书 参赛队员的荣誉证书</p>
                <p></p>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i> 三等奖
                </template>
                <p>三等奖 X 3 位 3,000RMB</p>
                <p>团体第三名荣誉证书 参赛队员的荣誉证书</p>
                <p></p>
              </tab-pane>
              <tab-pane>
                <template slot="label">
                  <i class="now-ui-icons ui-2_settings-90"></i>人气&优胜奖
                </template>
                <p>人气奖 X 1 位 1,000RMB 优胜奖 X 10 位 500RMB</p>
                <p></p>
                <p>团体人气&优胜奖荣誉证书 参赛队员的荣誉证书</p>
              </tab-pane>
            </tabs>
          </div>
          <!-- End Tabs on plain Card -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Tabs, TabPane } from '@/components'

export default {
  components: {
    Card,
    Tabs,
    TabPane,
  },
}
</script>
<style>
.tab-content.tab-content-padding {
  padding: 20px;
}

</style>
