import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        isUserLoggedIn: false
    },
    getters: {
        getUser: state => {
            if (!state.user) {
                state.user = JSON.parse(localStorage.getItem('user'));
            }
            return state.user;
        },
        isUserLoggedIn: state => {
            return state.isUserLoggedIn;
        }
    },
    mutations: {
        login(state, user) {
            state.user = user;
            state.isUserLoggedIn = true;
            localStorage.setItem('user', JSON.stringify(user));
        },
        logout(state) {
            state.user = {};
            state.isUserLoggedIn = false;
            localStorage.removeItem('user');
        }
    },
    actions: {
        login(context, user) {
            context.commit('login', user);
        },
        logout(context) {
            context.commit('logout');
        }
    },
    modules: {
    }
});