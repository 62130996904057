<template>
  <div>
    <div class="page-header clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="
          background-image: url('/img/2023seedcup.png');
          background-position: top center;
        "
      >
      </parallax>
      <div class="container">
        <vue-particles
          class="login-bg"
          color="#39AFFD"
          :particleOpacity="0.7"
          :particlesNumber="100"
          shapeType="circle"
          :particleSize="4"
          linesColor="#8DD1FE"
          :linesWidth="1"
          :lineLinked="true"
          :lineOpacity="0.4"
          :linesDistance="150"
          :moveSpeed="3"
          :hoverEffect="true"
          hoverMode="grab"
          :clickEffect="true"
          clickMode="push"
        >
        </vue-particles>
        <div class="content-center brand">
          <h1 class="h1-seo">点石·种子杯</h1>
          <h3>Tech makes world great again.</h3>
        </div>
      </div>
    </div>
    <tabs-section></tabs-section>
    <introduction></introduction>
    <sponser-list></sponser-list>
  </div>
</template>
<script>
import { Parallax } from '@/components'
import TabsSection from './components/Tabs'
import SponserList from './components/SponserList.vue'
import Introduction from './components/Introduction.vue'
export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax,
    TabsSection,
    SponserList,
    Introduction,
  },
}
</script>
<style scoped>
#particles-js {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
}
</style>
