<template>
  <div class="page-header-image" style="
      background-image: url('https://seed-cup.oss-cn-hangzhou.aliyuncs.com/seedcup/img/2022seedcup.png');
      background-size: cover;
      background-position: top center;
      min-height: 100vh;
    ">
    <vue-particles class="login-bg" color="#39AFFD" :particleOpacity="0.7" :particlesNumber="100" shapeType="circle"
      :particleSize="4" linesColor="#8DD1FE" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150"
      :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push">
    </vue-particles>

    <div class="TeamInfo">
      <Card class="card-teaminfo" plain>
        <Tabs slot="raw-content" tab-content-classes="tab-content-padding text-left">
          <tab-pane>
            <template slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i>用户信息
            </template>
            <template>
              <div class="row">
                <div class="col">
                  <p>姓名</p>
                </div>
                <div class="col">
                  <p>{{ user.name }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>昵称</p>
                </div>
                <div class="col">
                  <p>{{ user.username }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>所属队伍</p>
                </div>
                <div class="col">
                  <p v-if="user.teamname !== ''">{{ user.teamname }}</p>
                  <p v-else>暂无</p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>电话号码</p>
                </div>
                <div class="col">
                  <p>{{ user.phone }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>电子邮箱</p>
                </div>
                <div class="col">
                  <p>{{ user.email }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>学校</p>
                </div>
                <div class="col">
                  <p>{{ user.school }}</p>
                </div>
              </div>
              <div class="card-footer text-right">
                <n-button class="btn btn-round btn-lg" @click="logout()">退出登录</n-button>
              </div>
            </template>
          </tab-pane>
          <tab-pane v-if="user.teamname !== ''">
            <template slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i>队伍信息
            </template>
            <template>
              <div class="row">
                <div class="col">
                  <p>队伍名称</p>
                </div>
                <div class="col">
                  <p>{{ user.teamname }}</p>
                </div>
              </div>
              <div class="row">
                <el-col :span="12">
                  <div class="compensate-padding" style="padding-top: 11px"></div>
                  <p style="padding-left: 15px">队伍密钥</p>
                </el-col>
                <el-col :span="6">
                  <div class="compensate-padding" style="padding-top: 11px"></div>
                  <p style="padding-left: 15px">{{ team.key }}</p>
                </el-col>
                <el-col :span="6">
                  <n-button class="btn btn-round" @click="copykey(team.key)">复制密钥
                  </n-button>
                </el-col>
              </div>
              <div class="row">
                <div class="col">
                  <p>队伍分数</p>
                </div>
                <div class="col">
                  <p>{{ team.score }}</p>
                </div>
              </div>
              <div v-for="member in team.member" v-if="member.iscaptain" class="row">
                <div class="col">
                  <p>队长</p>
                </div>
                <div class="col">
                  <p>{{ member.username }}</p>
                </div>
              </div>
              <div v-for="member in team.member" v-if="!member.iscaptain" class="row">
                <el-col :span="12">
                  <div class="compensate-padding" style="padding-top: 11px"></div>
                  <p style="padding-left: 15px">队员</p>
                </el-col>
                <el-col :span="6">
                  <div class="compensate-padding" style="padding-top: 11px"></div>
                  <p style="padding-left: 15px">{{ member.username }}</p>
                </el-col>
                <el-col :span="6" v-if="user.iscaptain">
                  <n-button class="btn btn-round" @click="knockOutMember(member.id)">踢出队伍
                  </n-button>
                </el-col>
              </div>
            </template>
          </tab-pane>
          <tab-pane v-if="user.teamname == ''" :key="user.teamname">
            <template slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i>创建队伍
            </template>
            <template>
              <el-form :model="teamNameForm" ref="teamNameForm" :rules="teamNameRules">
                <div class="row">
                  <div class="col">
                    <p>队伍名称</p>
                  </div>
                  <div class="col">
                    <el-form-item prop="teamName">
                      <input v-model="teamNameForm.teamName" type="text" class="form-control" placeholder="队伍名称" />
                    </el-form-item>
                  </div>
                </div>
              </el-form>
              <div class="compensate-padding" style="padding: 90px 0"></div>
              <div class="card-footer text-right">
                <n-button class="btn btn-round btn-lg" @click="createTeam()">确认创建</n-button>
              </div>
            </template>
          </tab-pane>
          <tab-pane v-if="user.teamname == ''">
            <template slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i>加入队伍
            </template>
            <template>
              <el-form :model="teamKeyForm" ref="teamKeyForm" :rules="teamKeyRules">
                <div class="row">
                  <div class="col">
                    <p>队伍密钥</p>
                  </div>
                  <div class="col">
                    <el-form-item prop="teamKey">
                      <input v-model="teamKeyForm.teamKey" type="text" class="form-control" placeholder="队伍密钥" />
                    </el-form-item>
                  </div>
                </div>
              </el-form>
              <div class="compensate-padding" style="padding: 90px 0"></div>
              <div class="card-footer text-right">
                <n-button class="btn btn-round btn-lg" @click="joinTeam()">确认加入</n-button>
              </div>
            </template>
          </tab-pane>
          <tab-pane v-if="user.iscaptain">
            <template slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i>修改队名
            </template>
            <template>
              <el-form :model="teamNameForm" :rules="teamNameRules" ref="teamNameForm">
                <div class="row">
                  <div class="col">
                    <p>新队名</p>
                  </div>
                  <div class="col">
                    <el-form-item prop="teamName">
                      <input v-model="teamNameForm.teamName" type="text" class="form-control" placeholder="新队名" />
                    </el-form-item>
                  </div>
                </div>
              </el-form>
              <div class="compensate-padding" style="padding: 92px 0"></div>
              <div class="card-footer text-right">
                <n-button class="btn btn-round btn-lg" @click="changeTeamName()">确认修改</n-button>
              </div>
            </template>
          </tab-pane>
          <tab-pane v-if="user.teamname !== '' && !user.iscaptain">
            <template slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i>退出队伍
            </template>
            <template>
              <el-form :model="passwordForm" ref="passwordForm" :rules="passwordRules">
                <div class="row">
                  <div class="col">
                    <p>输入密码</p>
                  </div>
                  <div class="col">
                    <el-form-item prop="password">
                      <input v-model="passwordForm.password" type="password" class="form-control" placeholder="密码" />
                    </el-form-item>
                  </div>
                </div>
              </el-form>
              <div class="compensate-padding" style="padding: 90px 0"></div>
              <div class="card-footer text-right">
                <n-button class="btn btn-round btn-lg" @click="quitTeam()">确认退出</n-button>
              </div>
            </template>
          </tab-pane>
          <tab-pane v-if="user.teamname !== '' && user.iscaptain">
            <template slot="label">
              <i class="now-ui-icons ui-2_settings-90"></i>解散队伍
            </template>
            <template>
              <el-form :model="passwordForm" ref="passwordForm" :rules="passwordRules">
                <div class="row">
                  <div class="col">
                    <p>输入密码</p>
                  </div>
                  <div class="col">
                    <el-form-item prop="password">
                      <input v-model="passwordForm.password" type="password" class="form-control" placeholder="密码" />
                    </el-form-item>
                  </div>
                </div>
              </el-form>
              <div class="compensate-padding" style="padding: 92px 0"></div>
              <div class="card-footer text-right">
                <n-button class="btn btn-round btn-lg" @click="dismissTeam()">确认解散</n-button>
              </div>
            </template>
          </tab-pane>
          <tab-pane v-if="user.teamname !== ''">
            <template slot="label"><i class="now-ui-icons ui-2_settings-90"></i>上传算法文件</template>
            <div style="width=80%;margin=auto">
              <el-upload class="upload-demo" action="#" drag :limit="1" multiple :headers="headers" :auto-upload="false"
                :file-list="fileList" :on-exceed="handleExceed" :on-change="handleChange" :on-error="handleFailed"
                :on-remove="handleRemove" ref="upload" accept=".zip" :on-success="handleSuccess">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
              </el-upload>
            </div>
            <div class="card-footer text-right">
              <n-button class="btn btn-round btn-lg" @click="confirmUpload()">确认上传</n-button>
            </div>
          </tab-pane>
          <tab-pane v-if="user.teamname !== ''">
            <template slot="label"><i class="now-ui-icons ui-2_settings-90"></i>历史记录</template>
            <div class="row">
              <div class="col">
                <p>队伍名称</p>
              </div>
              <div class="col">
                <p>{{ user.teamname }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>排名</p>
              </div>
              <div class="col">
                <p>{{ record.rank }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>最终得分</p>
              </div>
              <div class="col">
                <p>{{ record.score }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p>提交记录
                  <span class="text-sm" style="cursor: pointer;" @click="refreshRecord">刷新</span>
                  <span style="width: 15px; display: inline-block;">
                    <img src="loading.gif" alt="" srcset="" v-show="loading" />
                  </span>
                </p>
              </div>
            </div>
            <div style="display: flex; flex-direction: row">
            </div>
            <div v-if="!Object.keys(record).length" class="details">
              当前尚无提交记录
            </div>
            <div v-else class="details">
              <!-- <div>
                <p>
                  <strong>
                    {{
                      '提交时间: ' +
                      new Date(record.create_time).toDateString() +
                      ' ' +
                      new Date(record.create_time).toLocaleTimeString() +
                      ' —— 总得分: ' +
                      ` ${this.record.credit}(回合总得分) + ` +
                      this.record.successTimes +
                      ` * ${this.rule.base}(执行成功得分) + ` +
                      this.record.winTimes +
                      ` * ${this.rule.winnerReward}(回合获胜得分) = ` +
                      computeTotalScore()
                    }}
                  </strong>
                </p>
              </div> -->
              <div v-for="(item, index) in record.details" :key="`item${index}`" class="row">
                <div class="col">
                  <span>
                    {{ index + 1 }}
                  </span>
                  <span class="text-sm">{{ item.timestamp }}</span>
                </div>
                <div class="col">
                  得分: {{ item.score }}
                </div>
                <div class="col">
                  <span>运行状态: </span>
                  <span v-if="item.error_reason === 'success'">
                    <span style="color:#89c273; padding: 0 4px;">成功</span>
                  </span>
                  <span v-else>
                    <span style="color: rgb(201, 42, 42); padding: 0 4px;">错误
                      <span class="text-sm" style="color: rgba(255, 255, 255, 0.85);cursor: pointer;"
                        @click="showErrorReason(item.error_reason)">查看</span>
                    </span>
                  </span>
                </div>
                <div class="col">
                  平均步数: {{ item.average_steps }}
                </div>
                <div class="col">
                  平均距离: {{ item.average_distance }}
                </div>
                <div class="col">
                  总时长: {{ item.total_time }}
                </div>

              </div>
            </div>
          </tab-pane>
        </Tabs>
      </Card>
    </div>
    <Modal :show="showModal" @close="closeModal">
      <div>{{ errorReason }}</div>
    </Modal>
  </div>
</template>

<script>
import { Card, Tabs, TabPane, Modal } from '@/components'
import request from '@/apis/request'
import { getToken, removeToken } from '@/utils/storage'

export default {
  inject: ['reload'],
  data() {
    let reg_pass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!reg_pass.test(value)) {
        callback(new Error('密码需包含数字和英文，长度6-20'))
      } else {
        if (this.changePasswordForm.confirmPassword !== '') {
          this.$refs.changePasswordForm.validateField('confirmPassword')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.changePasswordForm.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      user: {
        email: '',
        id: '',
        iscaptain: '',
        name: '',
        phone: '',
        school: '',
        teamname: '',
        username: '',
      },
      rule: {
        base: 12000,
        winnerReward: 5000,
      },
      record: {},
      team: {},
      changePasswordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      changePasswordRules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
      teamNameForm: {
        teamName: '',
      },
      teamNameRules: {
        teamName: [
          { required: true, message: '请输入队伍名称', trigger: 'blur' },
        ],
      },
      teamKeyForm: {
        teamKey: '',
      },
      teamKeyRules: {
        teamKey: [
          { required: true, message: '请输入队伍名称', trigger: 'blur' },
        ],
      },
      passwordForm: {
        password: '',
      },
      passwordRules: {
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      dialogOfUpload: false,
      fileList: [],
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      showModal: false,
      errorReason: ""
    }
  },
  components: {
    Card,
    Tabs,
    TabPane,
    Modal
  },
  methods: {
    computeTotalScore() {
      let totalScore = 0
      this.record.details.forEach((item) => {
        if (!item.status) {
          totalScore += this.rule.base + item.credit
          if (item.winners[0].player_name !== 'bot') {
            totalScore += this.rule.winnerReward
          }
        }
      })
      return totalScore
    },
    downloadLog(url) {
      var ele = document.createElement('a')
      ele.download = url
      ele.style.display = 'none'
      ele.href = url
      ele.target = '_blank' // 针对 ie模式 的浏览器
      // 触发点击
      document.body.appendChild(ele)
      ele.click()
    },
    copykey(value) {
      let eInput = document.createElement('input')
      eInput.value = value
      document.body.appendChild(eInput)
      eInput.select() //选择对象
      let copyText = document.execCommand('Copy') //执行浏览器的复制命令
      eInput.style.display = 'none'
      if (copyText) this.$message.success('复制成功')
    },
    createTeam() {
      console.log(this.teamNameForm)
      this.$refs.teamNameForm.validate((valid) => {
        if (valid) {
          request
            .post(
              '/team/create',
              {
                teamName: this.teamNameForm.teamName,
              },
              {
                // headers: {
                //     Authorization: getToken(),
                // },
              }
            )
            .then((res) => {
              if (res.code == 200) {
                this.$alert('创建成功', '提示', {
                  confirmButtonText: '确定',
                  callback: (action) => { },
                })
                this.$router.go(0)
              }
            })
            .catch((err) => {
              if (err.response.data.code == 400) {
                this.$alert(err.response.data.data)
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    joinTeam() {
      console.log(this.teamKeyForm)
      this.$refs.teamKeyForm.validate((valid) => {
        if (valid) {
          request
            .post(
              '/team/join',
              {
                teamKey: this.teamKeyForm.teamKey,
              },
              {
                // headers: {
                //     Authorization: getToken(),
                // },
              }
            )
            .then((res) => {
              if (res.code == 200) {
                this.$alert('加入成功', '提示', {
                  confirmButtonText: '确定',
                  callback: (action) => {
                    this.$router.go(0)
                  },
                })
              }
            })
            .catch((err) => {
              // if (err.response.data.code == 400) {
              //   this.$alert(err.response.data.data);
              // }
              this.$alert('密钥不存在')
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    changePassword() {
      console.log(this.changePasswordForm)
      this.$refs.changePasswordForm.validate((valid) => {
        if (valid) {
          request
            .post(
              '/user/change/pwd',
              {
                oldPassword: this.changePasswordForm.oldPassword,
                newPassword: this.changePasswordForm.newPassword,
              },
              {
                // headers: {
                //     Authorization: getToken()
                // }
              }
            )
            .then((res) => {
              console.log(res)
            })
            .catch((err) => {
              if (err.response.data.code == 400) {
                this.$alert(err.response.data.data)
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    changeTeamName() {
      console.log(this.teamNameForm)
      this.$refs.teamNameForm.validate((valid) => {
        if (valid) {
          request
            .post(
              '/team/change/teamname',
              {
                teamName: this.teamNameForm.teamName,
              },
              {
                // headers: {
                //     Authorization: getToken()
                // }
              }
            )
            .then((res) => {
              if (res.code == 200) {
                this.$alert('修改成功', '提示', {
                  confirmButtonText: '确定',
                  callback: (action) => {
                    this.$router.go(0)
                  },
                })
              }
            })
            .catch((err) => {
              if (err.response.data.code == 400) {
                this.$alert(err.response.data.data)
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    quitTeam() {
      console.log('quitTeam')
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          request
            .post(
              '/team/quit',
              {
                password: this.passwordForm.password,
              },
              {
                // headers: {
                //     Authorization: getToken()
                // }
              }
            )
            .then((res) => {
              if (res.code == 200) {
                this.$alert('退出成功', '提示', {
                  confirmButtonText: '确定',
                  callback: (action) => {
                    this.$router.go(0)
                  },
                })
              }
            })
            .catch((err) => {
              if (err.response.data.code == 400) {
                this.$alert(err.response.data.data)
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    dismissTeam() {
      console.log('dismissTeam')
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          request
            .post(
              '/team/dismiss',
              {
                password: this.passwordForm.password,
              },
              {
                // headers: {
                //     Authorization: getToken()
                // }
              }
            )
            .then((res) => {
              if (res.code == 200) {
                this.$alert('解散成功', '提示', {
                  confirmButtonText: '确定',
                  callback: (action) => {
                    this.$router.go(0)
                  },
                })
              }
            })
            .catch((err) => {
              if (err.response.data.code == 400) {
                this.$alert(err.response.data.data)
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    knockOutMember(id) {
      console.log('knockOutMember')
      request
        .post(
          '/team/kick',
          {
            uid: id,
          },
          {
            // headers: {
            //     Authorization: getToken()
            // }
          }
        )
        .then((res) => {
          if (res.code == 200) {
            this.$alert('踢出成功', '提示', {
              confirmButtonText: '确定',
              callback: (action) => {
                this.$router.go(0)
              },
            })
          }
        })
        .catch((err) => {
          if (err.response.data.code == 400) {
            this.$alert(err.response.data.data)
          }
        })
    },
    getTeamInfo() {
      request
        .get('/user/msg')
        .then((res) => {
          if (res.code == 200) {
            this.user = res.data.user
            this.team = res.data.team
          } else if (res.code === 401) {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
              callback: (action) => {
                this.$router.go(0)
              },
            })
          }
        })
        .catch((err) => {
          if (err.response.data.code == 400) {
            this.$alert(err.response.data.data)
          }
        })
      request
        .get('/team/history')
        .then((res) => {
          if (res.code == 200) {
            console.log('get last record')
            this.record = {
              ...this.record,
              details: [...res.data],
            }

            this.record.score = this.record.details.length ? Math.max(...this.record.details.map((item) => item.score)) : 0
            this.loading = false

          } else if (res.code === 401) {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
              callback: (action) => {
                this.$router.go(0)
              },
            })
          } else {
            // this.$alert(res.message)
          }
        })
        .catch((err) => {
          if (err.response.data.code == 400) {
            this.$alert(err.response.data.data)
          }
        })
    },
    handleExceed(file) {
      this.$message.error("最多上传一个文件")
    },
    handleChange(file, fileList) {
      // 文件格式校验
      if (file.name.endsWith('.zip')) {
        // 文件大小校验
        const fileSize = Number(file.size / 1024 / 1024)
        if (fileSize > 200) {
          this.$alert("文件大小不能超过 200 MB，请重新上传")
          this.$refs.upload.clearFiles()
        } else {
          this.fileList = fileList
        }
      } else {
        this.$alert("请上传 zip 格式文件")
        this.$refs.upload.clearFiles()
      }
    },
    handleSuccess(res, file, fileList) {
      console.log(res)
    },
    handleFailed(err, file, fileList) {
      console.log(err)
    },
    refreshRecord() {
      this.getTeamInfo()
      this.loading = true
    },
    confirmUpload() {
      //确认上传
      var param = new FormData()
      this.fileList.forEach((val, index) => {
        param.append('file', val.raw)
      })
      request
        .post('https://seedcup.dian.org.cn/api/team/uploadfile', param, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.code !== 200) {
            this.$alert(res.message)
            return
          }
          this.$message({
            message: '上传成功！',
            type: 'success',
            duration: 1000,
          })
        })
        .catch((err) => {
          console.log(err)
          if (err.response.data.code == 400) {
            this.$alert(err.response.data.data)
          }
        })
    },
    getFakeTeam() {
      this.team = {
        teamkey: '123456',
        captain: 'admin1',
        score: 100,
        members: [
          {
            name: 'admin1',
            username: 'admin1',
            school: 'admin',
            email: 'admin',
            phone: 'admin',
            teamname: 'admin',
            iscaptain: true,
            uid: 1,
          },
          {
            name: 'admin',
            username: 'admin',
            school: 'admin',
            email: 'admin',
            phone: 'admin',
            teamname: 'admin',
            iscaptain: false,
            uid: 2,
          },
          {
            name: 'admin',
            username: 'admin',
            school: 'admin',
            email: 'admin',
            phone: 'admin',
            teamname: 'admin',
            iscaptain: false,
            uid: 3,
          },
        ],
      }
      this.user = {
        name: 'admin',
        username: 'admin',
        school: 'admin',
        email: 'admin',
        phone: 'admin',
        teamname: 'admin',
        iscaptain: true,
        uid: 2,
      }
    },
    logout() {
      removeToken()
      this.$router.push('/login')
    },
    showErrorReason(errorReason) {
      this.showModal = true
      this.errorReason = errorReason
    },
    closeModal() {
      this.showModal = false
      this.errorReason = ''
    }
  },

  created() {
    if (getToken() == '') {
      this.$router.push('/login')
    }
    //this.getFakeTeam();
    this.getTeamInfo()
  },
  mounted() {
    request
      .get('/team/rank')
      .then((res) => {
        if (res.code !== 200 || res.data.length === 0) {
        } else {
          const myTeamRank = res.data.filter(
            (item) => item.name === this.user.teamname
          )[0]
          console.log(myTeamRank)
          this.record = {
            ...this.record,
            ...myTeamRank,
          }
        }
      })
      .catch((err) => {
        this.$alert('error')
        console.log(err)
      })
    this.getTeamInfo()
  },
}
</script>

<style scoped>
.TeamInfo {
  padding-top: 15%;
  margin-left: 5%;
  margin-right: 5%;
  text-align: left !important;
}

.card-teaminfo {
  color: white;
}

.last-row {
  margin-bottom: 20%;
}

.row {
  margin-top: 15px;
  margin-bottom: 20px;
}

.btn {
  background-color: transparent;
  border: 1px solid white;
}

.form-control {
  height: 40px !important;
}

:deep() .el-upload {
  width: 100%;
  margin: auto;
}

:deep() .el-upload .el-upload-dragger {
  width: 100%;
  margin: auto;
}

:deep() .el-upload .el-upload-text {
  width: 100%;
  margin: auto;
}

.upload-demo {
  width: 60%;
  margin: auto;
}

.details {
  /* display: flex; */
  /* flex-direction: column; */
  padding: 0 10%;
  /* align-items: center; */
}

.text-sm {
  font-size: small;
}
</style>
