<template>
  <navbar position="fixed" type="primary" :transparent="transparent" :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto">
    <template slot="navbar-menu">
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img src="https://seed-cup.oss-cn-hangzhou.aliyuncs.com/seedcup/img/dian-logo.gif" width="27%" />
        <a href="https://www.pcl.ac.cn/"></a>
      </router-link>
      <li class="nav-item">
        <a class="nav-link" @click="Mainclick">
          <i class="now-ui-icons design_app"></i>
          <p>Competition 大赛信息</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" @click="Rankclick">
          <i class="now-ui-icons design_app"></i>
          <p>Rank 排行</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" @click="Teamclick()">
          <i class="now-ui-icons design_app"></i>
          <p>TeamView 队伍信息</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" @click="LoginClick()">
          <i class="now-ui-icons design_app"></i>
          <p>Login 登录</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" @click="SignUpClick()">
          <i class="now-ui-icons design_app"></i>
          <p>SignUp 注册</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from "@/components";
import { Popover } from "element-ui";
export default {
  name: "main-navbar",
  // data(){
  // },
  methods: {
    Mainclick() {
      this.$router.push("/");
    },
    Rankclick() {
      this.$router.push("/rank");
    },
    LoginClick() {
      this.$router.push("/login");
    },
    SignUpClick() {
      this.$router.push("/signup")
    },
    Teamclick() {
      this.$router.push("/teaminfo");
    },
  },
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    Navbar,
    [Popover.name]: Popover,
  },
};
</script>

<style scoped>

</style>
