<template id="pager">
    <div>
        <div class="pager" v-if="totalPage > 1">
            <div class="row">
                <a v-if="pageArr.length > 1" href="javascript:void(0)" v-on:click="setPage('first')">首页</a>
                <a v-if="pageArr.length > 1" href="javascript:void(0)" v-on:click="setPage('prev')">上一页</a>
                <a v-if="index-4 >1 " v-on:click="setPage(1)" v-bind:class="[(1 == index ? 'active':'')]"
                    href="javascript:void(0)"> 1</a>
                <a v-if="(index-4 >1) && pageArr.length > 9" href="javascript:void(0)">...</a>
                <a href="javascript:void(0)" v-on:click="setPage(val)" v-bind:class="[(val == index ? 'active':'')]"
                    v-for="(val, pageindex) in pageArrPager" v-bind:key="pageindex">{{val}}</a>
                <a v-if="pageArr.length > index+4" href="javascript:void(0)">...</a>
                <a v-if="pageArr.length > 9 && pageArr.length > index+3" v-on:click="setPage(pageArr.length)"
                    v-bind:class="[(pageArr.length == index ? 'active':'')]"
                    href="javascript:void(0)">{{pageArr.length}}</a>
                <a v-if="pageArr.length > 1" href="javascript:void(0)" v-on:click="setPage('next')">下一页</a>
                <a v-if="pageArr.length > 1" href="javascript:void(0)" v-on:click="setPage('last')">尾页</a>
                <div class="text-center">转至</div>
                <fg-input class="No border" v-model="goPageIndex" />
                <div class="text-center">页</div>
                <a href="javascript:void(0)" v-on:click="goPage()">跳转</a>
            </div>
        </div>
    </div>
</template>
<script>
import {
    Checkbox,
    Radio,
    FormGroupInput,
    Switch
} from '@/components';



export default {
    name: 'Pager',
    data() {
        return {
            pageArr: [],
            pageArrPager: [],
            pagerIndex: this.index,
            goPageIndex: ''
        }
    },
    components: {
        [Checkbox.name]: Checkbox,
        [Radio.name]: Radio,
        [FormGroupInput.name]: FormGroupInput,
        [Switch.name]: Switch,
        [Option.name]: Option,
    },
    props: ['totalPage', 'index'],
    methods: {
        setPage(val) {
            if (val == 'first') { // 首页
                this.pagerIndex = 1;
            } else if (val == 'prev') { // 上一页
                if (this.pagerIndex > 1) {
                    this.pagerIndex = this.pagerIndex - 1
                }
            } else if (val == 'next') { // 下一页
                if (this.pagerIndex < this.totalPage) {
                    this.pagerIndex = this.pagerIndex + 1
                }
            } else if (val == 'last') { // 尾页
                this.pagerIndex = this.pageArr.length
            } else {
                this.pagerIndex = parseInt(val)
            }
            this.getData()
        },
        getData() {
            this.$emit('changeIndex', this.pagerIndex)
        },
        initPageArr() {
            this.pageArr = []
            for (var i = 0; i < this.totalPage; i++) {
                this.pageArr.push(i + 1)
            }
            if (this.pageArr.length > 9) { //分页的省略号优化
                if (this.pagerIndex > 5) {
                    if (this.pageArr.length - 3 > this.pagerIndex || this.pageArr.length - 3 == this.pagerIndex) {
                        this.pageArrPager = this.pageArr.slice(this.pagerIndex - 4, this.pagerIndex + 3)
                        // console.log(this.pageArrPager)
                    } else if ((this.pageArr.length - 3 < this.pagerIndex && this.pagerIndex < this.pageArr.length) || this.pagerIndex == this.pageArr.length) {
                        this.pageArrPager = this.pageArr.slice(this.pageArr.length - 8, this.pageArr.length)
                    }
                } else {
                    this.pageArrPager = this.pageArr.slice(0, 8)
                }
            } else {
                this.pageArrPager = this.pageArr.slice(0)
            }
        },
        goPage() {
            var re = /^[0-9]+$/ //正整数
            var goPageIndex = parseInt(this.goPageIndex)
            if (!(re.test(goPageIndex) && goPageIndex > 0 && goPageIndex <= this.totalPage)) {
                com.errorTip("请输入正确的页码数")
            }
            else {
                this.pagerIndex = goPageIndex;
                this.getData();
            }

        }
    },
    mounted() {
        this.initPageArr()
    },
    watch: {
        index: function (newindex, oldindex) {
            if (newindex != oldindex) {
                this.pagerIndex = newindex;
                this.initPageArr()
            }
        },
        totalPage: function (newTotalPage, oldTotalPage) {
            if (newTotalPage != oldTotalPage) {
                this.initPageArr()
            }
        }
    }
}
</script>
<style scoped>
.pager {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    position: inherit;
    bottom: 20px;
    font-size: 14px;
    color: #666;
}

.relative {
    position: relative;
}

.pager a {
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    padding: 4px;
    min-width: 16px;
    text-align: center;
    margin: 0 2px;
    color: #606266;
}

.pager a.active {
    background-color: #606266;
    color: #fff;
}

a:active,
a:focus,
a:hover,
a:visited {
    text-decoration: none;
    outline: 0;
}

a {
    color: #333;
    text-decoration: none;
    transition: .25s;
}

.pager .No {
    width: 50px;
    height: 1vh;
    border: none !important;
    text-align: center;
    margin: 0 5px;
}

.pager .btn {
    margin: 0 4px;
}

.btn-light-blue:hover {
    color: #333;
}

.btn {
    letter-spacing: 2px;
}

.btn {
    text-align: center;
}

.btn-light-blue {
    color: #666;
}

.bg-blue,
.btn-light-blue {
    margin: none;
}

.btn {
    /* display: inline-block; */
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border-radius: 4px;
    margin: none;
}

* {
    outline: none;
}

.pager>a {
    cursor: pointer;
}

.row {
    text-align: center;
    margin: 0 auto;
}

.text-center {
    text-align: center;
    padding-top: 0.7%;
}
</style>