<template>
  <div class="section section-signup" style="
      background-image: url('https://seed-cup.oss-cn-hangzhou.aliyuncs.com/seedcup/img/bg12.jpg');
      background-size: cover;
      background-position: top center;
      min-height: 700px;
    ">
    <div class="container">
      <div class="row">
        <card class="card-signup" header-classes="text-center" color="orange">
          <template slot="header">
            <h4 class="card-title title-up">注册</h4>
          </template>
          <template>
            <el-form :model="signupForm" ref="signupForm" :rules="rules">
              <el-form-item prop="name">
                <fg-input class="no-border" placeholder="姓名" addon-left-icon="now-ui-icons users_circle-08"
                  v-model="signupForm.name">
                </fg-input>
              </el-form-item>

              <el-form-item prop="username">
                <fg-input class="no-border" placeholder="用户名" addon-left-icon="now-ui-icons text_caps-small"
                  v-model="signupForm.username">
                </fg-input>
              </el-form-item>
              <el-form-item prop="email">
                <fg-input class="no-border" placeholder="电子邮箱" addon-left-icon="now-ui-icons ui-1_email-85"
                  v-model="signupForm.email">
                </fg-input>
              </el-form-item>
              <el-form-item prop="phone">
                <fg-input class="no-border" placeholder="电话号码" addon-left-icon="now-ui-icons transportation_air-baloon"
                  v-model="signupForm.phone">
                </fg-input>
              </el-form-item>
              <el-form-item prop="school">
                <fg-input class="no-border" placeholder="学校" addon-left-icon="now-ui-icons travel_istanbul"
                  v-model="signupForm.school">
                </fg-input>
              </el-form-item>
              <el-form-item prop="grade">
                <fg-input class="no-border" placeholder="年级( 1~4 )" addon-left-icon="now-ui-icons travel_istanbul"
                  v-model="signupForm.grade">
                </fg-input>
              </el-form-item>
              <el-form-item prop="password">
                <fg-input class="no-border" type="password" placeholder="密码" addon-left-icon="now-ui-icons objects_key-25"
                  v-model="signupForm.password">
                </fg-input>
              </el-form-item>
              <el-form-item prop="checkpass">
                <fg-input class="no-border" type="password" placeholder="确认密码"
                  addon-left-icon="now-ui-icons objects_key-25" v-model="signupForm.checkpass">
                </fg-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-row type="flex" class="row-bg" justify="space-between">
                  <el-col :span="14">
                    <div class="compensate-padding" style="padding: 11px 0">
                      <fg-input class="no-border" placeholder="验证码" addon-left-icon="now-ui-icons ui-1_send"
                        v-model="signupForm.code">
                      </fg-input>
                    </div>
                  </el-col>
                  <el-col :span="9">
                    <n-button class="btn btn-simple btn-round btn-white btn-mi" :disabled="btnBool" @click="send_sms">
                      {{ this.sms_interval }}
                    </n-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
            <div class="card-footer text-center">
              <n-button type="neutral" round size="lg" @click="getSignUp">Get Started</n-button>
            </div>
          </template>
        </card>
      </div>

      <div class="col text-center">
        <router-link to="/login" class="btn btn-simple btn-round btn-white btn-lg">
          View Login Page
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, FormGroupInput, Button } from "@/components"
import request from "@/apis/request"
import axios from "axios"
export default {
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    let reg_pass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
    let reg_mail =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    let validatePhone = (rule, value, callback) => {
      let phoneReg = /^1[3456789]\d{9}$/
      if (!value) {
        callback(new Error("请输入电话号码"))
      } else {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error("电话格式:13、14、15、16、17、18、19开头+9位阿拉伯数字"))
        }
      }
    }
    let validateMail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入电子邮箱"))
      } else if (!reg_mail.test(value)) {
        callback(new Error("请输入正确的电子邮箱！"))
      } else {
        callback()
      }
    }
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"))
      } else if (!reg_pass.test(value)) {
        callback(new Error("密码需包含数字和英文，长度6-20"))
      } else {
        if (this.signupForm.checkpass !== "") {
          this.$refs.signupForm.validateField("checkpass")
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"))
      } else if (value !== this.signupForm.password) {
        callback(new Error("两次输入密码不一致!"))
      } else {
        callback()
      }
    }
    let validateGrade = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入年级"))
      } else {
        const g = Number.parseInt(value)
        if (isNaN(g)) {
          callback(new Error("请输入 1 ~ 4 数字"))
        } else if (g > 4 || g < 1) {
          callback(new Error("仅限 1 ~ 4 年级参加"))
        } else {
          callback()
        }
      }

    }
    return {
      signupForm: {
        name: "",
        username: "",
        password: "",
        checkpass: "",
        school: "",
        grade: "",
        phone: "",
        email: "",
        code: "",
      },
      is_send: false,
      sms_interval: "获取验证码",
      message: "",
      btnBool: false,
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        school: [{ required: true, message: "请输入学校", trigger: "blur" }],
        grade: [
          { required: true, validator: validateGrade, trigger: "blur" }
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        checkpass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        email: [{ required: true, validator: validateMail, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    }
  },
  methods: {
    getSignUp: function () {
      console.log("注册信息：", this.signupForm)
      this.$refs.signupForm.validate((valid) => {
        console.log(valid)
        if (valid) {
          console.log("信息有效")
          request.post("/user/register", this.signupForm)
            .then((res) => {
              if (res.code == 200) {
                this.$alert('注册成功')
                this.$router.push('/')
              } else if (res.code == 202) {
                this.$alert(res.data)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log("校验错误")
        }
      })
    },
    send_sms: function () {
      if (!this.signupForm.phone) return
      if (!this.signupForm.phone.match(/^1[3-9][0-9]{9}$/)) {
        console.log(err)
        this.$message({
          message: "手机号有误",
          type: "warning",
          duration: 1000,
          onClose: () => {
            this.signupForm.phone = ""
          },
        })
        return false
      }
      this.is_send = true
      this.btnBool = true
      //校验手机号如果存在就不用注册，直接去登录
      // this.$http
      //   .get(this.$BASE_URL + "user/check_phone/", {
      //     params: { phone: this.mobile },
      //   })
      //   .then((res) => {
      //     console.log(res.data);
      //     if (res.data.code == 100) {
      //       this.is_send = false;

      //       this.$message({
      //         message: "该账号已经存在，请直接去登录",
      //         type: "success",
      //       });
      //       this.is_send = true;
      //     } else {
      //       this.is_send = true;
      //       this.$message({
      //         message: "可以正常注册",
      //         type: "warning",
      //       });
      //     }
      //   });
      this.sending()
    },
    sending: function () {
      if (!this.is_send) return
      this.is_send = false
      let sms_interval_time = 60
      this.sms_interval = "发送中"
      let timer = setInterval(() => {
        if (sms_interval_time <= 1) {
          clearInterval(timer)
          this.sms_interval = "获取验证码"
          this.btnBool = false
          this.is_send = true // 重新回复点击发送功能的条件
        } else {
          sms_interval_time -= 1
          this.sms_interval = `${sms_interval_time}秒后再发`
        }
      }, 1000)
      request
        .post("/user/sms", { phone: this.signupForm.phone })
        .then((res) => {
          console.log(res.data)
          if (res.code == 200) {
            this.$message({
              message: "验证码已发送",
              type: "success",
            })
          } else {
            this.$message({
              message: "发送失败，请稍后再试。",
              type: "error",
            })
          }
        })
    },
  },
}
</script>
<style></style>
