import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import MainNavbar from './layout/MainNavbar.vue';
import Rank from './pages/Rank.vue';
import Login from "./pages/login.vue";
import SignUp from "./pages/signup.vue";
import TeamInfo from "./pages/TeamInfo.vue";


Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      }
    },
    {
      path: '/login',
      name: 'login',
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      }
    },
    {
      path: '/signup',
      name: 'signup',
      components: { default: SignUp, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      }
    },
    {
      path: '/teaminfo',
      name: 'teaminfo',
      components: { default: TeamInfo, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      }
    },
    {
      path: '/rank',
      name: 'rank',
      components: { default: Rank, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      }
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
