export function setToken(content) {
  if (!localStorage) {
    throw new Error("您的浏览器不支持localStorage，请尝试更新浏览器");
  }
  localStorage.setItem("token", content);
}
export function getToken() {
  if (!localStorage) {
    return "";
  }
  const token = localStorage.getItem("token") || "";
  return token;
}
export function removeToken() {
  localStorage.removeItem("token");
}
export function setUserState(content) {
  if (!localStorage) {
    throw new Error("您的浏览器不支持localStorage，请尝试更新浏览器");
  }
  localStorage.setItem("userState", JSON.stringify(content));
}
export function getUserState() {
  if (!localStorage) {
    return "";
  }
  const userState = localStorage.getItem("userState") || "";
  return JSON.parse(userState);
}
export function removeUserState() {
  localStorage.removeItem("userState");
}
