<template>
  <div>
    <div
      class="page-header clear-filter"
      filter-color="blue"
      style="
        background-image: url('https://seed-cup.oss-cn-hangzhou.aliyuncs.com/seedcup/img/bg20.jpg');
        background-size: cover;
        height: auto;
        max-height: fit-content;
      "
    >
      <parallax class="page-header-image"> </parallax>
      <div class="container">
        <div class="container-font">
          <h1 class="h1-seo">主办方</h1>

          <h3>华中科技大学电子信息与通信学院</h3>

          <h3>华中科技大学启明学院</h3>

          <h1 class="h1-seo">承办方</h1>

          <h3>华中科技大学Dian团队</h3>

          <h3>华中科技大学电子信息与通信学院科学与技术协会</h3>

          <h1 class="h1-seo">赞助方</h1>

          <h3>武汉喻信点石科技有限公司</h3>

          <h1 class="h1-seo">支持单位</h1>

          <h3>共青团华中科技大学委员会</h3>

          <h3>华中科技大学大学生科学技术协会</h3>
          <h3></h3>
          <h3></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SponserList',
  components: {
  },
}
</script>

<style scoped>

.container-font {
  text-align: center;
  color: #FFFFFF;
  padding: 0 0px;
  width: 100%;
  max-width: 880px;
}

</style>
